.PageLoader_loader__ZUM2Z {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--beige);
  opacity: 0;
  visibility: hidden;
  transform-origin: left;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}

.PageLoader_loader__ZUM2Z.PageLoader_changing__q35wJ {
  opacity: 1;
  visibility: visible;
}

.PageLoader_loader__ZUM2Z.PageLoader_changing__q35wJ .PageLoader_layer1__fRt4Q, 
.PageLoader_loader__ZUM2Z.PageLoader_changing__q35wJ .PageLoader_layer2__VKKbl {
  transform: scaleX(1);
}

.PageLoader_loader__ZUM2Z.PageLoader_changing__q35wJ .PageLoader_layer3__QOOz4 {
  transform: translateX(-50%) scaleX(1) skew(0);
  transition-delay: .4s;
}

.PageLoader_loader__ZUM2Z.PageLoader_changing__q35wJ .PageLoader_loading_icon__8lOhA {
  opacity: 1;
}

.PageLoader_layer1__fRt4Q, .PageLoader_layer2__VKKbl, .PageLoader_layer3__QOOz4 {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  transform: scaleX(0);
  transition: transform .2s ease-in-out;
}

.PageLoader_layer1__fRt4Q {
  left: 0;
  background-color: var(--blue);
  transform-origin: left;
}

.PageLoader_layer2__VKKbl {
  right: 0;
  background-color: var(--yellow-500);
  transform-origin: right;
}

.PageLoader_layer3__QOOz4 {
  background-color: var(--beige);
  left: 50%;
  width: 100%;
  min-width: 100px;
  transform-origin: center;
  transform: translateX(-50%) scaleX(0) skew(45deg);
  transition-delay: .3s;
}

@media (max-width: 768px) {
  .PageLoader_layer1__fRt4Q, .PageLoader_layer2__VKKbl {
    width: 100%;
    height: 50%;
    transform: scaleY(0);
  }
  
  .PageLoader_layer1__fRt4Q {
    transform-origin: top;
  }

  .PageLoader_layer2__VKKbl {
    top: 50%;
    transform-origin: bottom;
  }

  .PageLoader_loader__ZUM2Z.PageLoader_changing__q35wJ .PageLoader_layer1__fRt4Q, 
  .PageLoader_loader__ZUM2Z.PageLoader_changing__q35wJ .PageLoader_layer2__VKKbl {
    transform: scaleY(1);
  }

  .PageLoader_layer3__QOOz4 {
    transform: translateX(-50%) scaleX(0);
  }
}

.PageLoader_loading_icon__8lOhA {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100px;
  height: 100px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  transition-delay: .4s;
}

.PageLoader_loading_icon__8lOhA div {
  position: absolute;
  border: 4px solid var(--blue);
  opacity: 1;
  border-radius: 50%;
  animation: PageLoader_ripple__ZWCfr 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.PageLoader_loading_icon__8lOhA div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes PageLoader_ripple__ZWCfr {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/*
This File is autogenerated directly from Figma by ZOL Theme Generator.
Don't change it, your modifications may be deleted.
*/

:root {
  /* BREAKPOINTS */
  --mobile: 768px;
  --tablet: 1024px;
  --laptop: 1200px;
  --desktop: 1440px;

  /* COLORS */
  --beige: #f9f8f0;
  --black: #032230;
  --black-alpha-2: rgba(3, 34, 48, 0.2);
  --black-alpha-5: rgba(3, 34, 48, 0.5);
  --black-alpha-7: rgba(3, 34, 48, 0.7);
  --blue: #004666;
  --blue-100: #eceee9;
  --grey: #bfc2c4;
  --grey-alpha-1: rgba(191, 194, 196, 0.1);
  --red: #e64010;
  --red-100: #ffddd3;
  --white: #ffffff;
  --yellow-200: #faefc0;
  --yellow-500: #ffcc00;

  /* FONTS */
  --font-h1-desktop-default: 500 3.5rem/4rem var(--oswald-font);
  --font-h1-mobile-default: 500 2.625rem/3rem var(--oswald-font);
  --font-h2-desktop-default: 500 3rem/3.5rem var(--oswald-font);
  --font-h2-mobile-default: 500 1.75rem/2.125rem var(--oswald-font);
  --font-h3-desktop-default: 500 1.5rem/2rem var(--lato-font);
  --font-h3-mobile-default: 500 1.25rem/1.5rem var(--lato-font);
  --font-p1-desktop-default: 500 1.125rem/1.5rem var(--lato-font);
  --font-p1-mobile-default: 500 1rem/1.5rem var(--lato-font);
  --font-p2-desktop-default: 500 1rem/1.25rem var(--lato-font);
  --font-p2-mobile-default: 500 0.875rem/1.125rem var(--lato-font);
  --font-p3-desktop-default: 500 0.75rem/1rem var(--lato-font);
  --font-p3-mobile-default: 500 0.75rem/1rem var(--lato-font);

  /* SPACING */
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-24: 1.5rem;
  --spacing-34: 2.125rem;
  --spacing-40: 2.5rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-160: 10rem;

  /* RADIUS */
  --radius-s: 2px;

  /* EFFECTS */
  --background-blur: null;

  /* GRID LAYOUT */
  --size-row: 131px;
  --grid-template-desktop: 
    [full-start] 1fr 
    [main-start] repeat(12,minmax(0,var(--size-row))) [main-end] 
    1fr [full-end];

  --grid-template-mobile: 
    [full-start] 1fr 
    [main-start] repeat(12,minmax(0,var(--size-row))) [main-end] 
    1fr [full-end];
}

/*
**** UTILS ****
*/

/* COLORS */
.background-color-beige {
  background-color: var(--beige);
}
.font-color-beige {
  color: var(--beige);
}
.border-color-beige {
  border-color: var(--beige);
}
.background-color-black {
  background-color: var(--black);
}
.font-color-black {
  color: var(--black);
}
.border-color-black {
  border-color: var(--black);
}
.background-color-blue {
  background-color: var(--blue);
}
.font-color-blue {
  color: var(--blue);
}
.border-color-blue {
  border-color: var(--blue);
}
.background-color-grey {
  background-color: var(--grey);
}
.font-color-grey {
  color: var(--grey);
}
.border-color-grey {
  border-color: var(--grey);
}
.background-color-red {
  background-color: var(--red);
}
.font-color-red {
  color: var(--red);
}
.border-color-red {
  border-color: var(--red);
}
.background-color-white {
  background-color: var(--white);
}
.font-color-white {
  color: var(--white);
}
.border-color-white {
  border-color: var(--white);
}
.background-color-yellow-200 {
  background-color: var(--yellow-200);
}
.font-color-yellow-200 {
  color: var(--yellow-200);
}
.border-color-yellow-200 {
  border-color: var(--yellow-200);
}
.background-color-yellow-500 {
  background-color: var(--yellow-500);
}
.font-color-yellow-500 {
  color: var(--yellow-500);
}
.border-color-yellow-500 {
  border-color: var(--yellow-500);
}

/* FONTS */
.h1-desktop-default {
  font: var(--font-h1-desktop-default);
  font-weight: 800;
}
.h2-desktop-default {
  font: var(--font-h2-desktop-default);
  font-weight: 800;
}
.h3-desktop-default {
  font: var(--font-h3-desktop-default);
}
.h3-desktop-bold {
  font: var(--font-h3-desktop-default);
  font-weight: 800;
}

.p1-desktop-default {
  font: var(--font-p1-desktop-default);
}
.p1-desktop-bold {
  font: var(--font-p1-desktop-default);
  font-weight: 800;
}
.p2-desktop-default {
  font: var(--font-p2-desktop-default);
}
.p2-desktop-bold {
  font: var(--font-p2-desktop-default);
  font-weight: 800;
}
.p3-desktop-default {
  font: var(--font-p3-desktop-default);
}
.p3-desktop-bold {
  font: var(--font-p3-desktop-default);
  font-weight: 800;
}

@media (max-width: 768px) {
  .h1-mobile-default {
    font: var(--font-h1-mobile-default);
    font-weight: 800;
  }
  .h2-mobile-default {
    font: var(--font-h2-mobile-default);
    font-weight: 800;
  }
  .h3-mobile-default {
    font: var(--font-h3-mobile-default);
  }
  .h3-mobile-bold {
    font: var(--font-h3-mobile-default);
    font-weight: 800;
  }
  .p1-mobile-default {
    font: var(--font-p1-mobile-default);
  }
  .p1-mobile-bold {
    font: var(--font-p1-mobile-default);
    font-weight: 800;
  }
  .p2-mobile-default {
    font: var(--font-p2-mobile-default);
  }
  .p2-mobile-bold {
    font: var(--font-p2-mobile-default);
    font-weight: 800;
  }
  .p3-mobile-default {
    font: var(--font-p3-mobile-default);
  }
  .p3-mobile-bold {
    font: var(--font-p3-mobile-default);
    font-weight: 800;
  }
}

/* SPACING */

/* GAP */
.gap-4 {
  gap: var(--spacing-4);
}

.gap-8{
  gap: var(--spacing-8);
}

.gap-16{
  gap: var(--spacing-16);
}

.gap-24{
  gap: var(--spacing-24);
}

.gap-40{
  gap: var(--spacing-40);
}

.gap-64{
  gap: var(--spacing-64);
}

.gap-80{
  gap: var(--spacing-80);
}

.gap-160{
  gap: var(--spacing-160);
}


/* AUTO */
.margin-left-auto {
  margin-left: auto;
}
.margin-right-auto {
  margin-right: auto;
}

@media (max-width: 1024px) {
  .margin-left-auto-mobile {
    margin-left: auto;
  }
}
@media (max-width: 1200px) {
  .margin-right-auto-mobile {
    margin-right: auto;
  }
}

.margin-12 {
  margin: var(--spacing-12);
}
.margin-top-12 {
  margin-top: var(--spacing-12);
}
.margin-bottom-12 {
  margin-bottom: var(--spacing-12);
}
.margin-left-12 {
  margin-left: var(--spacing-12);
}
.margin-right-12 {
  margin-right: var(--spacing-12);
}
.padding-12 {
  padding: var(--spacing-12);
}
.padding-top-12 {
  padding-top: var(--spacing-12);
}
.padding-bottom-12 {
  padding-bottom: var(--spacing-12);
}
.padding-left-12 {
  padding-left: var(--spacing-12);
}
.padding-right-12 {
  padding-right: var(--spacing-12);
}
.top-12 {
  top: var(--spacing-12);
}
.bottom-12 {
  bottom: var(--spacing-12);
}
.left-12 {
  left: var(--spacing-12);
}
.right-12 {
  right: var(--spacing-12);
}


.margin-64 {
  margin: var(--spacing-64);
}
.margin-top-64 {
  margin-top: var(--spacing-64);
}
.margin-bottom-64 {
  margin-bottom: var(--spacing-64);
}
.margin-left-64 {
  margin-left: var(--spacing-64);
}
.margin-right-64 {
  margin-right: var(--spacing-64);
}
.padding-64 {
  padding: var(--spacing-64);
}
.padding-top-64 {
  padding-top: var(--spacing-64);
}
.padding-bottom-64 {
  padding-bottom: var(--spacing-64);
}
.padding-left-64 {
  padding-left: var(--spacing-64);
}
.padding-right-64 {
  padding-right: var(--spacing-64);
}
.top-64 {
  top: var(--spacing-64);
}
.bottom-64 {
  bottom: var(--spacing-64);
}
.left-64 {
  left: var(--spacing-64);
}
.right-64 {
  right: var(--spacing-64);
}


.margin-80 {
  margin: var(--spacing-80);
}
.margin-top-80 {
  margin-top: var(--spacing-80);
}
.margin-bottom-80 {
  margin-bottom: var(--spacing-80);
}
.margin-left-80 {
  margin-left: var(--spacing-80);
}
.margin-right-80 {
  margin-right: var(--spacing-80);
}
.padding-80 {
  padding: var(--spacing-80);
}
.padding-top-80 {
  padding-top: var(--spacing-80);
}
.padding-bottom-80 {
  padding-bottom: var(--spacing-80);
}
.padding-left-80 {
  padding-left: var(--spacing-80);
}
.padding-right-80 {
  padding-right: var(--spacing-80);
}
.top-80 {
  top: var(--spacing-80);
}
.bottom-80 {
  bottom: var(--spacing-80);
}
.left-80 {
  left: var(--spacing-80);
}
.right-80 {
  right: var(--spacing-80);
}


.margin-16 {
  margin: var(--spacing-16);
}
.margin-top-16 {
  margin-top: var(--spacing-16);
}
.margin-bottom-16 {
  margin-bottom: var(--spacing-16);
}
.margin-left-16 {
  margin-left: var(--spacing-16);
}
.margin-right-16 {
  margin-right: var(--spacing-16);
}
.padding-16 {
  padding: var(--spacing-16);
}
.padding-top-16 {
  padding-top: var(--spacing-16);
}
.padding-bottom-16 {
  padding-bottom: var(--spacing-16);
}
.padding-left-16 {
  padding-left: var(--spacing-16);
}
.padding-right-16 {
  padding-right: var(--spacing-16);
}
.top-16 {
  top: var(--spacing-16);
}
.bottom-16 {
  bottom: var(--spacing-16);
}
.left-16 {
  left: var(--spacing-16);
}
.right-16 {
  right: var(--spacing-16);
}


.margin-8 {
  margin: var(--spacing-8);
}
.margin-top-8 {
  margin-top: var(--spacing-8);
}
.margin-bottom-8 {
  margin-bottom: var(--spacing-8);
}
.margin-left-8 {
  margin-left: var(--spacing-8);
}
.margin-right-8 {
  margin-right: var(--spacing-8);
}
.padding-8 {
  padding: var(--spacing-8);
}
.padding-top-8 {
  padding-top: var(--spacing-8);
}
.padding-bottom-8 {
  padding-bottom: var(--spacing-8);
}
.padding-left-8 {
  padding-left: var(--spacing-8);
}
.padding-right-8 {
  padding-right: var(--spacing-8);
}
.top-8 {
  top: var(--spacing-8);
}
.bottom-8 {
  bottom: var(--spacing-8);
}
.left-8 {
  left: var(--spacing-8);
}
.right-8 {
  right: var(--spacing-8);
}


.margin-160 {
  margin: var(--spacing-160);
}
.margin-top-160 {
  margin-top: var(--spacing-160);
}
.margin-bottom-160 {
  margin-bottom: var(--spacing-160);
}
.margin-left-160 {
  margin-left: var(--spacing-160);
}
.margin-right-160 {
  margin-right: var(--spacing-160);
}
.padding-160 {
  padding: var(--spacing-160);
}
.padding-top-160 {
  padding-top: var(--spacing-160);
}
.padding-bottom-160 {
  padding-bottom: var(--spacing-160);
}
.padding-left-160 {
  padding-left: var(--spacing-160);
}
.padding-right-160 {
  padding-right: var(--spacing-160);
}
.top-160 {
  top: var(--spacing-160);
}
.bottom-160 {
  bottom: var(--spacing-160);
}
.left-160 {
  left: var(--spacing-160);
}
.right-160 {
  right: var(--spacing-160);
}


.margin-40 {
  margin: var(--spacing-40);
}
.margin-top-40 {
  margin-top: var(--spacing-40);
}
.margin-bottom-40 {
  margin-bottom: var(--spacing-40);
}
.margin-left-40 {
  margin-left: var(--spacing-40);
}
.margin-right-40 {
  margin-right: var(--spacing-40);
}
.padding-40 {
  padding: var(--spacing-40);
}
.padding-top-40 {
  padding-top: var(--spacing-40);
}
.padding-bottom-40 {
  padding-bottom: var(--spacing-40);
}
.padding-left-40 {
  padding-left: var(--spacing-40);
}
.padding-right-40 {
  padding-right: var(--spacing-40);
}
.top-40 {
  top: var(--spacing-40);
}
.bottom-40 {
  bottom: var(--spacing-40);
}
.left-40 {
  left: var(--spacing-40);
}
.right-40 {
  right: var(--spacing-40);
}


.margin-24 {
  margin: var(--spacing-24);
}
.margin-top-24 {
  margin-top: var(--spacing-24);
}
.margin-bottom-24 {
  margin-bottom: var(--spacing-24);
}
.margin-left-24 {
  margin-left: var(--spacing-24);
}
.margin-right-24 {
  margin-right: var(--spacing-24);
}
.padding-24 {
  padding: var(--spacing-24);
}
.padding-top-24 {
  padding-top: var(--spacing-24);
}
.padding-bottom-24 {
  padding-bottom: var(--spacing-24);
}
.padding-left-24 {
  padding-left: var(--spacing-24);
}
.padding-right-24 {
  padding-right: var(--spacing-24);
}
.top-24 {
  top: var(--spacing-24);
}
.bottom-24 {
  bottom: var(--spacing-24);
}
.left-24 {
  left: var(--spacing-24);
}
.right-24 {
  right: var(--spacing-24);
}


.margin-4 {
  margin: var(--spacing-4);
}
.margin-top-4 {
  margin-top: var(--spacing-4);
}
.margin-bottom-4 {
  margin-bottom: var(--spacing-4);
}
.margin-left-4 {
  margin-left: var(--spacing-4);
}
.margin-right-4 {
  margin-right: var(--spacing-4);
}
.padding-4 {
  padding: var(--spacing-4);
}
.padding-top-4 {
  padding-top: var(--spacing-4);
}
.padding-bottom-4 {
  padding-bottom: var(--spacing-4);
}
.padding-left-4 {
  padding-left: var(--spacing-4);
}
.padding-right-4 {
  padding-right: var(--spacing-4);
}
.top-4 {
  top: var(--spacing-4);
}
.bottom-4 {
  bottom: var(--spacing-4);
}
.left-4 {
  left: var(--spacing-4);
}
.right-4 {
  right: var(--spacing-4);
}


@media (max-width: 768px) {
  .margin-12-mobile {
    margin: var(--spacing-12);
  }
  .margin-top-12-mobile {
    margin-top: var(--spacing-12);
  }
  .margin-bottom-12-mobile {
    margin-bottom: var(--spacing-12);
  }
  .margin-left-12-mobile {
    margin-left: var(--spacing-12);
  }
  .margin-right-12-mobile {
    margin-right: var(--spacing-12);
  }
  .padding-12-mobile {
    padding: var(--spacing-12);
  }
  .padding-top-12-mobile {
    padding-top: var(--spacing-12);
  }
  .padding-bottom-12-mobile {
    padding-bottom: var(--spacing-12);
  }
  .padding-left-12-mobile {
    padding-left: var(--spacing-12);
  }
  .padding-right-12-mobile {
    padding-right: var(--spacing-12);
  }
  .top-12-mobile {
    top: var(--spacing-12);
  }
  .bottom-12-mobile {
    bottom: var(--spacing-12);
  }
  .left-12-mobile {
    left: var(--spacing-12);
  }
  .right-12-mobile {
    right: var(--spacing-12);
  }
}

@media (max-width: 1024px) {
  .margin-12-tablet {
    margin: var(--spacing-12);
  }
  .margin-top-12-tablet {
    margin-top: var(--spacing-12);
  }
  .margin-bottom-12-tablet {
    margin-bottom: var(--spacing-12);
  }
  .margin-left-12-tablet {
    margin-left: var(--spacing-12);
  }
  .margin-right-12-tablet {
    margin-right: var(--spacing-12);
  }
  .padding-12-tablet {
    padding: var(--spacing-12);
  }
  .padding-top-12-tablet {
    padding-top: var(--spacing-12);
  }
  .padding-bottom-12-tablet {
    padding-bottom: var(--spacing-12);
  }
  .padding-left-12-tablet {
    padding-left: var(--spacing-12);
  }
  .padding-right-12-tablet {
    padding-right: var(--spacing-12);
  }
  .top-12-tablet {
    top: var(--spacing-12);
  }
  .bottom-12-tablet {
    bottom: var(--spacing-12);
  }
  .left-12-tablet {
    left: var(--spacing-12);
  }
  .right-12-tablet {
    right: var(--spacing-12);
  }
}

@media (max-width: 1200px) {
  .margin-12-laptop {
    margin: var(--spacing-12);
  }
  .margin-top-12-laptop {
    margin-top: var(--spacing-12);
  }
  .margin-bottom-12-laptop {
    margin-bottom: var(--spacing-12);
  }
  .margin-left-12-laptop {
    margin-left: var(--spacing-12);
  }
  .margin-right-12-laptop {
    margin-right: var(--spacing-12);
  }
  .padding-12-laptop {
    padding: var(--spacing-12);
  }
  .padding-top-12-laptop {
    padding-top: var(--spacing-12);
  }
  .padding-bottom-12-laptop {
    padding-bottom: var(--spacing-12);
  }
  .padding-left-12-laptop {
    padding-left: var(--spacing-12);
  }
  .padding-right-12-laptop {
    padding-right: var(--spacing-12);
  }
  .top-12-laptop {
    top: var(--spacing-12);
  }
  .bottom-12-laptop {
    bottom: var(--spacing-12);
  }
  .left-12-laptop {
    left: var(--spacing-12);
  }
  .right-12-laptop {
    right: var(--spacing-12);
  }
}

@media (max-width: 1440px) {
  .margin-12-desktop {
    margin: var(--spacing-12);
  }
  .margin-top-12-desktop {
    margin-top: var(--spacing-12);
  }
  .margin-bottom-12-desktop {
    margin-bottom: var(--spacing-12);
  }
  .margin-left-12-desktop {
    margin-left: var(--spacing-12);
  }
  .margin-right-12-desktop {
    margin-right: var(--spacing-12);
  }
  .padding-12-desktop {
    padding: var(--spacing-12);
  }
  .padding-top-12-desktop {
    padding-top: var(--spacing-12);
  }
  .padding-bottom-12-desktop {
    padding-bottom: var(--spacing-12);
  }
  .padding-left-12-desktop {
    padding-left: var(--spacing-12);
  }
  .padding-right-12-desktop {
    padding-right: var(--spacing-12);
  }
  .top-12-desktop {
    top: var(--spacing-12);
  }
  .bottom-12-desktop {
    bottom: var(--spacing-12);
  }
  .left-12-desktop {
    left: var(--spacing-12);
  }
  .right-12-desktop {
    right: var(--spacing-12);
  }
}

@media (max-width: 768px) {
  .margin-64-mobile {
    margin: var(--spacing-64);
  }
  .margin-top-64-mobile {
    margin-top: var(--spacing-64);
  }
  .margin-bottom-64-mobile {
    margin-bottom: var(--spacing-64);
  }
  .margin-left-64-mobile {
    margin-left: var(--spacing-64);
  }
  .margin-right-64-mobile {
    margin-right: var(--spacing-64);
  }
  .padding-64-mobile {
    padding: var(--spacing-64);
  }
  .padding-top-64-mobile {
    padding-top: var(--spacing-64);
  }
  .padding-bottom-64-mobile {
    padding-bottom: var(--spacing-64);
  }
  .padding-left-64-mobile {
    padding-left: var(--spacing-64);
  }
  .padding-right-64-mobile {
    padding-right: var(--spacing-64);
  }
  .top-64-mobile {
    top: var(--spacing-64);
  }
  .bottom-64-mobile {
    bottom: var(--spacing-64);
  }
  .left-64-mobile {
    left: var(--spacing-64);
  }
  .right-64-mobile {
    right: var(--spacing-64);
  }
}

@media (max-width: 1024px) {
  .margin-64-tablet {
    margin: var(--spacing-64);
  }
  .margin-top-64-tablet {
    margin-top: var(--spacing-64);
  }
  .margin-bottom-64-tablet {
    margin-bottom: var(--spacing-64);
  }
  .margin-left-64-tablet {
    margin-left: var(--spacing-64);
  }
  .margin-right-64-tablet {
    margin-right: var(--spacing-64);
  }
  .padding-64-tablet {
    padding: var(--spacing-64);
  }
  .padding-top-64-tablet {
    padding-top: var(--spacing-64);
  }
  .padding-bottom-64-tablet {
    padding-bottom: var(--spacing-64);
  }
  .padding-left-64-tablet {
    padding-left: var(--spacing-64);
  }
  .padding-right-64-tablet {
    padding-right: var(--spacing-64);
  }
  .top-64-tablet {
    top: var(--spacing-64);
  }
  .bottom-64-tablet {
    bottom: var(--spacing-64);
  }
  .left-64-tablet {
    left: var(--spacing-64);
  }
  .right-64-tablet {
    right: var(--spacing-64);
  }
}

@media (max-width: 1200px) {
  .margin-64-laptop {
    margin: var(--spacing-64);
  }
  .margin-top-64-laptop {
    margin-top: var(--spacing-64);
  }
  .margin-bottom-64-laptop {
    margin-bottom: var(--spacing-64);
  }
  .margin-left-64-laptop {
    margin-left: var(--spacing-64);
  }
  .margin-right-64-laptop {
    margin-right: var(--spacing-64);
  }
  .padding-64-laptop {
    padding: var(--spacing-64);
  }
  .padding-top-64-laptop {
    padding-top: var(--spacing-64);
  }
  .padding-bottom-64-laptop {
    padding-bottom: var(--spacing-64);
  }
  .padding-left-64-laptop {
    padding-left: var(--spacing-64);
  }
  .padding-right-64-laptop {
    padding-right: var(--spacing-64);
  }
  .top-64-laptop {
    top: var(--spacing-64);
  }
  .bottom-64-laptop {
    bottom: var(--spacing-64);
  }
  .left-64-laptop {
    left: var(--spacing-64);
  }
  .right-64-laptop {
    right: var(--spacing-64);
  }
}

@media (max-width: 1440px) {
  .margin-64-desktop {
    margin: var(--spacing-64);
  }
  .margin-top-64-desktop {
    margin-top: var(--spacing-64);
  }
  .margin-bottom-64-desktop {
    margin-bottom: var(--spacing-64);
  }
  .margin-left-64-desktop {
    margin-left: var(--spacing-64);
  }
  .margin-right-64-desktop {
    margin-right: var(--spacing-64);
  }
  .padding-64-desktop {
    padding: var(--spacing-64);
  }
  .padding-top-64-desktop {
    padding-top: var(--spacing-64);
  }
  .padding-bottom-64-desktop {
    padding-bottom: var(--spacing-64);
  }
  .padding-left-64-desktop {
    padding-left: var(--spacing-64);
  }
  .padding-right-64-desktop {
    padding-right: var(--spacing-64);
  }
  .top-64-desktop {
    top: var(--spacing-64);
  }
  .bottom-64-desktop {
    bottom: var(--spacing-64);
  }
  .left-64-desktop {
    left: var(--spacing-64);
  }
  .right-64-desktop {
    right: var(--spacing-64);
  }
}


@media (max-width: 768px) {
  .margin-80-mobile {
    margin: var(--spacing-80);
  }
  .margin-top-80-mobile {
    margin-top: var(--spacing-80);
  }
  .margin-bottom-80-mobile {
    margin-bottom: var(--spacing-80);
  }
  .margin-left-80-mobile {
    margin-left: var(--spacing-80);
  }
  .margin-right-80-mobile {
    margin-right: var(--spacing-80);
  }
  .padding-80-mobile {
    padding: var(--spacing-80);
  }
  .padding-top-80-mobile {
    padding-top: var(--spacing-80);
  }
  .padding-bottom-80-mobile {
    padding-bottom: var(--spacing-80);
  }
  .padding-left-80-mobile {
    padding-left: var(--spacing-80);
  }
  .padding-right-80-mobile {
    padding-right: var(--spacing-80);
  }
  .top-80-mobile {
    top: var(--spacing-80);
  }
  .bottom-80-mobile {
    bottom: var(--spacing-80);
  }
  .left-80-mobile {
    left: var(--spacing-80);
  }
  .right-80-mobile {
    right: var(--spacing-80);
  }
}

@media (max-width: 1024px) {
  .margin-80-tablet {
    margin: var(--spacing-80);
  }
  .margin-top-80-tablet {
    margin-top: var(--spacing-80);
  }
  .margin-bottom-80-tablet {
    margin-bottom: var(--spacing-80);
  }
  .margin-left-80-tablet {
    margin-left: var(--spacing-80);
  }
  .margin-right-80-tablet {
    margin-right: var(--spacing-80);
  }
  .padding-80-tablet {
    padding: var(--spacing-80);
  }
  .padding-top-80-tablet {
    padding-top: var(--spacing-80);
  }
  .padding-bottom-80-tablet {
    padding-bottom: var(--spacing-80);
  }
  .padding-left-80-tablet {
    padding-left: var(--spacing-80);
  }
  .padding-right-80-tablet {
    padding-right: var(--spacing-80);
  }
  .top-80-tablet {
    top: var(--spacing-80);
  }
  .bottom-80-tablet {
    bottom: var(--spacing-80);
  }
  .left-80-tablet {
    left: var(--spacing-80);
  }
  .right-80-tablet {
    right: var(--spacing-80);
  }
}

@media (max-width: 1200px) {
  .margin-80-laptop {
    margin: var(--spacing-80);
  }
  .margin-top-80-laptop {
    margin-top: var(--spacing-80);
  }
  .margin-bottom-80-laptop {
    margin-bottom: var(--spacing-80);
  }
  .margin-left-80-laptop {
    margin-left: var(--spacing-80);
  }
  .margin-right-80-laptop {
    margin-right: var(--spacing-80);
  }
  .padding-80-laptop {
    padding: var(--spacing-80);
  }
  .padding-top-80-laptop {
    padding-top: var(--spacing-80);
  }
  .padding-bottom-80-laptop {
    padding-bottom: var(--spacing-80);
  }
  .padding-left-80-laptop {
    padding-left: var(--spacing-80);
  }
  .padding-right-80-laptop {
    padding-right: var(--spacing-80);
  }
  .top-80-laptop {
    top: var(--spacing-80);
  }
  .bottom-80-laptop {
    bottom: var(--spacing-80);
  }
  .left-80-laptop {
    left: var(--spacing-80);
  }
  .right-80-laptop {
    right: var(--spacing-80);
  }
}

@media (max-width: 1440px) {
  .margin-80-desktop {
    margin: var(--spacing-80);
  }
  .margin-top-80-desktop {
    margin-top: var(--spacing-80);
  }
  .margin-bottom-80-desktop {
    margin-bottom: var(--spacing-80);
  }
  .margin-left-80-desktop {
    margin-left: var(--spacing-80);
  }
  .margin-right-80-desktop {
    margin-right: var(--spacing-80);
  }
  .padding-80-desktop {
    padding: var(--spacing-80);
  }
  .padding-top-80-desktop {
    padding-top: var(--spacing-80);
  }
  .padding-bottom-80-desktop {
    padding-bottom: var(--spacing-80);
  }
  .padding-left-80-desktop {
    padding-left: var(--spacing-80);
  }
  .padding-right-80-desktop {
    padding-right: var(--spacing-80);
  }
  .top-80-desktop {
    top: var(--spacing-80);
  }
  .bottom-80-desktop {
    bottom: var(--spacing-80);
  }
  .left-80-desktop {
    left: var(--spacing-80);
  }
  .right-80-desktop {
    right: var(--spacing-80);
  }
}


@media (max-width: 768px) {
  .margin-16-mobile {
    margin: var(--spacing-16);
  }
  .margin-top-16-mobile {
    margin-top: var(--spacing-16);
  }
  .margin-bottom-16-mobile {
    margin-bottom: var(--spacing-16);
  }
  .margin-left-16-mobile {
    margin-left: var(--spacing-16);
  }
  .margin-right-16-mobile {
    margin-right: var(--spacing-16);
  }
  .padding-16-mobile {
    padding: var(--spacing-16);
  }
  .padding-top-16-mobile {
    padding-top: var(--spacing-16);
  }
  .padding-bottom-16-mobile {
    padding-bottom: var(--spacing-16);
  }
  .padding-left-16-mobile {
    padding-left: var(--spacing-16);
  }
  .padding-right-16-mobile {
    padding-right: var(--spacing-16);
  }
  .top-16-mobile {
    top: var(--spacing-16);
  }
  .bottom-16-mobile {
    bottom: var(--spacing-16);
  }
  .left-16-mobile {
    left: var(--spacing-16);
  }
  .right-16-mobile {
    right: var(--spacing-16);
  }
}

@media (max-width: 1024px) {
  .margin-16-tablet {
    margin: var(--spacing-16);
  }
  .margin-top-16-tablet {
    margin-top: var(--spacing-16);
  }
  .margin-bottom-16-tablet {
    margin-bottom: var(--spacing-16);
  }
  .margin-left-16-tablet {
    margin-left: var(--spacing-16);
  }
  .margin-right-16-tablet {
    margin-right: var(--spacing-16);
  }
  .padding-16-tablet {
    padding: var(--spacing-16);
  }
  .padding-top-16-tablet {
    padding-top: var(--spacing-16);
  }
  .padding-bottom-16-tablet {
    padding-bottom: var(--spacing-16);
  }
  .padding-left-16-tablet {
    padding-left: var(--spacing-16);
  }
  .padding-right-16-tablet {
    padding-right: var(--spacing-16);
  }
  .top-16-tablet {
    top: var(--spacing-16);
  }
  .bottom-16-tablet {
    bottom: var(--spacing-16);
  }
  .left-16-tablet {
    left: var(--spacing-16);
  }
  .right-16-tablet {
    right: var(--spacing-16);
  }
}

@media (max-width: 1200px) {
  .margin-16-laptop {
    margin: var(--spacing-16);
  }
  .margin-top-16-laptop {
    margin-top: var(--spacing-16);
  }
  .margin-bottom-16-laptop {
    margin-bottom: var(--spacing-16);
  }
  .margin-left-16-laptop {
    margin-left: var(--spacing-16);
  }
  .margin-right-16-laptop {
    margin-right: var(--spacing-16);
  }
  .padding-16-laptop {
    padding: var(--spacing-16);
  }
  .padding-top-16-laptop {
    padding-top: var(--spacing-16);
  }
  .padding-bottom-16-laptop {
    padding-bottom: var(--spacing-16);
  }
  .padding-left-16-laptop {
    padding-left: var(--spacing-16);
  }
  .padding-right-16-laptop {
    padding-right: var(--spacing-16);
  }
  .top-16-laptop {
    top: var(--spacing-16);
  }
  .bottom-16-laptop {
    bottom: var(--spacing-16);
  }
  .left-16-laptop {
    left: var(--spacing-16);
  }
  .right-16-laptop {
    right: var(--spacing-16);
  }
}

@media (max-width: 1440px) {
  .margin-16-desktop {
    margin: var(--spacing-16);
  }
  .margin-top-16-desktop {
    margin-top: var(--spacing-16);
  }
  .margin-bottom-16-desktop {
    margin-bottom: var(--spacing-16);
  }
  .margin-left-16-desktop {
    margin-left: var(--spacing-16);
  }
  .margin-right-16-desktop {
    margin-right: var(--spacing-16);
  }
  .padding-16-desktop {
    padding: var(--spacing-16);
  }
  .padding-top-16-desktop {
    padding-top: var(--spacing-16);
  }
  .padding-bottom-16-desktop {
    padding-bottom: var(--spacing-16);
  }
  .padding-left-16-desktop {
    padding-left: var(--spacing-16);
  }
  .padding-right-16-desktop {
    padding-right: var(--spacing-16);
  }
  .top-16-desktop {
    top: var(--spacing-16);
  }
  .bottom-16-desktop {
    bottom: var(--spacing-16);
  }
  .left-16-desktop {
    left: var(--spacing-16);
  }
  .right-16-desktop {
    right: var(--spacing-16);
  }
}


@media (max-width: 768px) {
  .margin-8-mobile {
    margin: var(--spacing-8);
  }
  .margin-top-8-mobile {
    margin-top: var(--spacing-8);
  }
  .margin-bottom-8-mobile {
    margin-bottom: var(--spacing-8);
  }
  .margin-left-8-mobile {
    margin-left: var(--spacing-8);
  }
  .margin-right-8-mobile {
    margin-right: var(--spacing-8);
  }
  .padding-8-mobile {
    padding: var(--spacing-8);
  }
  .padding-top-8-mobile {
    padding-top: var(--spacing-8);
  }
  .padding-bottom-8-mobile {
    padding-bottom: var(--spacing-8);
  }
  .padding-left-8-mobile {
    padding-left: var(--spacing-8);
  }
  .padding-right-8-mobile {
    padding-right: var(--spacing-8);
  }
  .top-8-mobile {
    top: var(--spacing-8);
  }
  .bottom-8-mobile {
    bottom: var(--spacing-8);
  }
  .left-8-mobile {
    left: var(--spacing-8);
  }
  .right-8-mobile {
    right: var(--spacing-8);
  }
}

@media (max-width: 1024px) {
  .margin-8-tablet {
    margin: var(--spacing-8);
  }
  .margin-top-8-tablet {
    margin-top: var(--spacing-8);
  }
  .margin-bottom-8-tablet {
    margin-bottom: var(--spacing-8);
  }
  .margin-left-8-tablet {
    margin-left: var(--spacing-8);
  }
  .margin-right-8-tablet {
    margin-right: var(--spacing-8);
  }
  .padding-8-tablet {
    padding: var(--spacing-8);
  }
  .padding-top-8-tablet {
    padding-top: var(--spacing-8);
  }
  .padding-bottom-8-tablet {
    padding-bottom: var(--spacing-8);
  }
  .padding-left-8-tablet {
    padding-left: var(--spacing-8);
  }
  .padding-right-8-tablet {
    padding-right: var(--spacing-8);
  }
  .top-8-tablet {
    top: var(--spacing-8);
  }
  .bottom-8-tablet {
    bottom: var(--spacing-8);
  }
  .left-8-tablet {
    left: var(--spacing-8);
  }
  .right-8-tablet {
    right: var(--spacing-8);
  }
}

@media (max-width: 1200px) {
  .margin-8-laptop {
    margin: var(--spacing-8);
  }
  .margin-top-8-laptop {
    margin-top: var(--spacing-8);
  }
  .margin-bottom-8-laptop {
    margin-bottom: var(--spacing-8);
  }
  .margin-left-8-laptop {
    margin-left: var(--spacing-8);
  }
  .margin-right-8-laptop {
    margin-right: var(--spacing-8);
  }
  .padding-8-laptop {
    padding: var(--spacing-8);
  }
  .padding-top-8-laptop {
    padding-top: var(--spacing-8);
  }
  .padding-bottom-8-laptop {
    padding-bottom: var(--spacing-8);
  }
  .padding-left-8-laptop {
    padding-left: var(--spacing-8);
  }
  .padding-right-8-laptop {
    padding-right: var(--spacing-8);
  }
  .top-8-laptop {
    top: var(--spacing-8);
  }
  .bottom-8-laptop {
    bottom: var(--spacing-8);
  }
  .left-8-laptop {
    left: var(--spacing-8);
  }
  .right-8-laptop {
    right: var(--spacing-8);
  }
}

@media (max-width: 1440px) {
  .margin-8-desktop {
    margin: var(--spacing-8);
  }
  .margin-top-8-desktop {
    margin-top: var(--spacing-8);
  }
  .margin-bottom-8-desktop {
    margin-bottom: var(--spacing-8);
  }
  .margin-left-8-desktop {
    margin-left: var(--spacing-8);
  }
  .margin-right-8-desktop {
    margin-right: var(--spacing-8);
  }
  .padding-8-desktop {
    padding: var(--spacing-8);
  }
  .padding-top-8-desktop {
    padding-top: var(--spacing-8);
  }
  .padding-bottom-8-desktop {
    padding-bottom: var(--spacing-8);
  }
  .padding-left-8-desktop {
    padding-left: var(--spacing-8);
  }
  .padding-right-8-desktop {
    padding-right: var(--spacing-8);
  }
  .top-8-desktop {
    top: var(--spacing-8);
  }
  .bottom-8-desktop {
    bottom: var(--spacing-8);
  }
  .left-8-desktop {
    left: var(--spacing-8);
  }
  .right-8-desktop {
    right: var(--spacing-8);
  }
}


@media (max-width: 768px) {
  .margin-160-mobile {
    margin: var(--spacing-160);
  }
  .margin-top-160-mobile {
    margin-top: var(--spacing-160);
  }
  .margin-bottom-160-mobile {
    margin-bottom: var(--spacing-160);
  }
  .margin-left-160-mobile {
    margin-left: var(--spacing-160);
  }
  .margin-right-160-mobile {
    margin-right: var(--spacing-160);
  }
  .padding-160-mobile {
    padding: var(--spacing-160);
  }
  .padding-top-160-mobile {
    padding-top: var(--spacing-160);
  }
  .padding-bottom-160-mobile {
    padding-bottom: var(--spacing-160);
  }
  .padding-left-160-mobile {
    padding-left: var(--spacing-160);
  }
  .padding-right-160-mobile {
    padding-right: var(--spacing-160);
  }
  .top-160-mobile {
    top: var(--spacing-160);
  }
  .bottom-160-mobile {
    bottom: var(--spacing-160);
  }
  .left-160-mobile {
    left: var(--spacing-160);
  }
  .right-160-mobile {
    right: var(--spacing-160);
  }
}

@media (max-width: 1024px) {
  .margin-160-tablet {
    margin: var(--spacing-160);
  }
  .margin-top-160-tablet {
    margin-top: var(--spacing-160);
  }
  .margin-bottom-160-tablet {
    margin-bottom: var(--spacing-160);
  }
  .margin-left-160-tablet {
    margin-left: var(--spacing-160);
  }
  .margin-right-160-tablet {
    margin-right: var(--spacing-160);
  }
  .padding-160-tablet {
    padding: var(--spacing-160);
  }
  .padding-top-160-tablet {
    padding-top: var(--spacing-160);
  }
  .padding-bottom-160-tablet {
    padding-bottom: var(--spacing-160);
  }
  .padding-left-160-tablet {
    padding-left: var(--spacing-160);
  }
  .padding-right-160-tablet {
    padding-right: var(--spacing-160);
  }
  .top-160-tablet {
    top: var(--spacing-160);
  }
  .bottom-160-tablet {
    bottom: var(--spacing-160);
  }
  .left-160-tablet {
    left: var(--spacing-160);
  }
  .right-160-tablet {
    right: var(--spacing-160);
  }
}

@media (max-width: 1200px) {
  .margin-160-laptop {
    margin: var(--spacing-160);
  }
  .margin-top-160-laptop {
    margin-top: var(--spacing-160);
  }
  .margin-bottom-160-laptop {
    margin-bottom: var(--spacing-160);
  }
  .margin-left-160-laptop {
    margin-left: var(--spacing-160);
  }
  .margin-right-160-laptop {
    margin-right: var(--spacing-160);
  }
  .padding-160-laptop {
    padding: var(--spacing-160);
  }
  .padding-top-160-laptop {
    padding-top: var(--spacing-160);
  }
  .padding-bottom-160-laptop {
    padding-bottom: var(--spacing-160);
  }
  .padding-left-160-laptop {
    padding-left: var(--spacing-160);
  }
  .padding-right-160-laptop {
    padding-right: var(--spacing-160);
  }
  .top-160-laptop {
    top: var(--spacing-160);
  }
  .bottom-160-laptop {
    bottom: var(--spacing-160);
  }
  .left-160-laptop {
    left: var(--spacing-160);
  }
  .right-160-laptop {
    right: var(--spacing-160);
  }
}

@media (max-width: 1440px) {
  .margin-160-desktop {
    margin: var(--spacing-160);
  }
  .margin-top-160-desktop {
    margin-top: var(--spacing-160);
  }
  .margin-bottom-160-desktop {
    margin-bottom: var(--spacing-160);
  }
  .margin-left-160-desktop {
    margin-left: var(--spacing-160);
  }
  .margin-right-160-desktop {
    margin-right: var(--spacing-160);
  }
  .padding-160-desktop {
    padding: var(--spacing-160);
  }
  .padding-top-160-desktop {
    padding-top: var(--spacing-160);
  }
  .padding-bottom-160-desktop {
    padding-bottom: var(--spacing-160);
  }
  .padding-left-160-desktop {
    padding-left: var(--spacing-160);
  }
  .padding-right-160-desktop {
    padding-right: var(--spacing-160);
  }
  .top-160-desktop {
    top: var(--spacing-160);
  }
  .bottom-160-desktop {
    bottom: var(--spacing-160);
  }
  .left-160-desktop {
    left: var(--spacing-160);
  }
  .right-160-desktop {
    right: var(--spacing-160);
  }
}


@media (max-width: 768px) {
  .margin-40-mobile {
    margin: var(--spacing-40);
  }
  .margin-top-40-mobile {
    margin-top: var(--spacing-40);
  }
  .margin-bottom-40-mobile {
    margin-bottom: var(--spacing-40);
  }
  .margin-left-40-mobile {
    margin-left: var(--spacing-40);
  }
  .margin-right-40-mobile {
    margin-right: var(--spacing-40);
  }
  .padding-40-mobile {
    padding: var(--spacing-40);
  }
  .padding-top-40-mobile {
    padding-top: var(--spacing-40);
  }
  .padding-bottom-40-mobile {
    padding-bottom: var(--spacing-40);
  }
  .padding-left-40-mobile {
    padding-left: var(--spacing-40);
  }
  .padding-right-40-mobile {
    padding-right: var(--spacing-40);
  }
  .top-40-mobile {
    top: var(--spacing-40);
  }
  .bottom-40-mobile {
    bottom: var(--spacing-40);
  }
  .left-40-mobile {
    left: var(--spacing-40);
  }
  .right-40-mobile {
    right: var(--spacing-40);
  }
}

@media (max-width: 1024px) {
  .margin-40-tablet {
    margin: var(--spacing-40);
  }
  .margin-top-40-tablet {
    margin-top: var(--spacing-40);
  }
  .margin-bottom-40-tablet {
    margin-bottom: var(--spacing-40);
  }
  .margin-left-40-tablet {
    margin-left: var(--spacing-40);
  }
  .margin-right-40-tablet {
    margin-right: var(--spacing-40);
  }
  .padding-40-tablet {
    padding: var(--spacing-40);
  }
  .padding-top-40-tablet {
    padding-top: var(--spacing-40);
  }
  .padding-bottom-40-tablet {
    padding-bottom: var(--spacing-40);
  }
  .padding-left-40-tablet {
    padding-left: var(--spacing-40);
  }
  .padding-right-40-tablet {
    padding-right: var(--spacing-40);
  }
  .top-40-tablet {
    top: var(--spacing-40);
  }
  .bottom-40-tablet {
    bottom: var(--spacing-40);
  }
  .left-40-tablet {
    left: var(--spacing-40);
  }
  .right-40-tablet {
    right: var(--spacing-40);
  }
}

@media (max-width: 1200px) {
  .margin-40-laptop {
    margin: var(--spacing-40);
  }
  .margin-top-40-laptop {
    margin-top: var(--spacing-40);
  }
  .margin-bottom-40-laptop {
    margin-bottom: var(--spacing-40);
  }
  .margin-left-40-laptop {
    margin-left: var(--spacing-40);
  }
  .margin-right-40-laptop {
    margin-right: var(--spacing-40);
  }
  .padding-40-laptop {
    padding: var(--spacing-40);
  }
  .padding-top-40-laptop {
    padding-top: var(--spacing-40);
  }
  .padding-bottom-40-laptop {
    padding-bottom: var(--spacing-40);
  }
  .padding-left-40-laptop {
    padding-left: var(--spacing-40);
  }
  .padding-right-40-laptop {
    padding-right: var(--spacing-40);
  }
  .top-40-laptop {
    top: var(--spacing-40);
  }
  .bottom-40-laptop {
    bottom: var(--spacing-40);
  }
  .left-40-laptop {
    left: var(--spacing-40);
  }
  .right-40-laptop {
    right: var(--spacing-40);
  }
}

@media (max-width: 1440px) {
  .margin-40-desktop {
    margin: var(--spacing-40);
  }
  .margin-top-40-desktop {
    margin-top: var(--spacing-40);
  }
  .margin-bottom-40-desktop {
    margin-bottom: var(--spacing-40);
  }
  .margin-left-40-desktop {
    margin-left: var(--spacing-40);
  }
  .margin-right-40-desktop {
    margin-right: var(--spacing-40);
  }
  .padding-40-desktop {
    padding: var(--spacing-40);
  }
  .padding-top-40-desktop {
    padding-top: var(--spacing-40);
  }
  .padding-bottom-40-desktop {
    padding-bottom: var(--spacing-40);
  }
  .padding-left-40-desktop {
    padding-left: var(--spacing-40);
  }
  .padding-right-40-desktop {
    padding-right: var(--spacing-40);
  }
  .top-40-desktop {
    top: var(--spacing-40);
  }
  .bottom-40-desktop {
    bottom: var(--spacing-40);
  }
  .left-40-desktop {
    left: var(--spacing-40);
  }
  .right-40-desktop {
    right: var(--spacing-40);
  }
}


@media (max-width: 768px) {
  .margin-24-mobile {
    margin: var(--spacing-24);
  }
  .margin-top-24-mobile {
    margin-top: var(--spacing-24);
  }
  .margin-bottom-24-mobile {
    margin-bottom: var(--spacing-24);
  }
  .margin-left-24-mobile {
    margin-left: var(--spacing-24);
  }
  .margin-right-24-mobile {
    margin-right: var(--spacing-24);
  }
  .padding-24-mobile {
    padding: var(--spacing-24);
  }
  .padding-top-24-mobile {
    padding-top: var(--spacing-24);
  }
  .padding-bottom-24-mobile {
    padding-bottom: var(--spacing-24);
  }
  .padding-left-24-mobile {
    padding-left: var(--spacing-24);
  }
  .padding-right-24-mobile {
    padding-right: var(--spacing-24);
  }
  .top-24-mobile {
    top: var(--spacing-24);
  }
  .bottom-24-mobile {
    bottom: var(--spacing-24);
  }
  .left-24-mobile {
    left: var(--spacing-24);
  }
  .right-24-mobile {
    right: var(--spacing-24);
  }
}

@media (max-width: 1024px) {
  .margin-24-tablet {
    margin: var(--spacing-24);
  }
  .margin-top-24-tablet {
    margin-top: var(--spacing-24);
  }
  .margin-bottom-24-tablet {
    margin-bottom: var(--spacing-24);
  }
  .margin-left-24-tablet {
    margin-left: var(--spacing-24);
  }
  .margin-right-24-tablet {
    margin-right: var(--spacing-24);
  }
  .padding-24-tablet {
    padding: var(--spacing-24);
  }
  .padding-top-24-tablet {
    padding-top: var(--spacing-24);
  }
  .padding-bottom-24-tablet {
    padding-bottom: var(--spacing-24);
  }
  .padding-left-24-tablet {
    padding-left: var(--spacing-24);
  }
  .padding-right-24-tablet {
    padding-right: var(--spacing-24);
  }
  .top-24-tablet {
    top: var(--spacing-24);
  }
  .bottom-24-tablet {
    bottom: var(--spacing-24);
  }
  .left-24-tablet {
    left: var(--spacing-24);
  }
  .right-24-tablet {
    right: var(--spacing-24);
  }
}

@media (max-width: 1200px) {
  .margin-24-laptop {
    margin: var(--spacing-24);
  }
  .margin-top-24-laptop {
    margin-top: var(--spacing-24);
  }
  .margin-bottom-24-laptop {
    margin-bottom: var(--spacing-24);
  }
  .margin-left-24-laptop {
    margin-left: var(--spacing-24);
  }
  .margin-right-24-laptop {
    margin-right: var(--spacing-24);
  }
  .padding-24-laptop {
    padding: var(--spacing-24);
  }
  .padding-top-24-laptop {
    padding-top: var(--spacing-24);
  }
  .padding-bottom-24-laptop {
    padding-bottom: var(--spacing-24);
  }
  .padding-left-24-laptop {
    padding-left: var(--spacing-24);
  }
  .padding-right-24-laptop {
    padding-right: var(--spacing-24);
  }
  .top-24-laptop {
    top: var(--spacing-24);
  }
  .bottom-24-laptop {
    bottom: var(--spacing-24);
  }
  .left-24-laptop {
    left: var(--spacing-24);
  }
  .right-24-laptop {
    right: var(--spacing-24);
  }
}

@media (max-width: 1440px) {
  .margin-24-desktop {
    margin: var(--spacing-24);
  }
  .margin-top-24-desktop {
    margin-top: var(--spacing-24);
  }
  .margin-bottom-24-desktop {
    margin-bottom: var(--spacing-24);
  }
  .margin-left-24-desktop {
    margin-left: var(--spacing-24);
  }
  .margin-right-24-desktop {
    margin-right: var(--spacing-24);
  }
  .padding-24-desktop {
    padding: var(--spacing-24);
  }
  .padding-top-24-desktop {
    padding-top: var(--spacing-24);
  }
  .padding-bottom-24-desktop {
    padding-bottom: var(--spacing-24);
  }
  .padding-left-24-desktop {
    padding-left: var(--spacing-24);
  }
  .padding-right-24-desktop {
    padding-right: var(--spacing-24);
  }
  .top-24-desktop {
    top: var(--spacing-24);
  }
  .bottom-24-desktop {
    bottom: var(--spacing-24);
  }
  .left-24-desktop {
    left: var(--spacing-24);
  }
  .right-24-desktop {
    right: var(--spacing-24);
  }
}


@media (max-width: 768px) {
  .margin-4-mobile {
    margin: var(--spacing-4);
  }
  .margin-top-4-mobile {
    margin-top: var(--spacing-4);
  }
  .margin-bottom-4-mobile {
    margin-bottom: var(--spacing-4);
  }
  .margin-left-4-mobile {
    margin-left: var(--spacing-4);
  }
  .margin-right-4-mobile {
    margin-right: var(--spacing-4);
  }
  .padding-4-mobile {
    padding: var(--spacing-4);
  }
  .padding-top-4-mobile {
    padding-top: var(--spacing-4);
  }
  .padding-bottom-4-mobile {
    padding-bottom: var(--spacing-4);
  }
  .padding-left-4-mobile {
    padding-left: var(--spacing-4);
  }
  .padding-right-4-mobile {
    padding-right: var(--spacing-4);
  }
  .top-4-mobile {
    top: var(--spacing-4);
  }
  .bottom-4-mobile {
    bottom: var(--spacing-4);
  }
  .left-4-mobile {
    left: var(--spacing-4);
  }
  .right-4-mobile {
    right: var(--spacing-4);
  }
}

@media (max-width: 1024px) {
  .margin-4-tablet {
    margin: var(--spacing-4);
  }
  .margin-top-4-tablet {
    margin-top: var(--spacing-4);
  }
  .margin-bottom-4-tablet {
    margin-bottom: var(--spacing-4);
  }
  .margin-left-4-tablet {
    margin-left: var(--spacing-4);
  }
  .margin-right-4-tablet {
    margin-right: var(--spacing-4);
  }
  .padding-4-tablet {
    padding: var(--spacing-4);
  }
  .padding-top-4-tablet {
    padding-top: var(--spacing-4);
  }
  .padding-bottom-4-tablet {
    padding-bottom: var(--spacing-4);
  }
  .padding-left-4-tablet {
    padding-left: var(--spacing-4);
  }
  .padding-right-4-tablet {
    padding-right: var(--spacing-4);
  }
  .top-4-tablet {
    top: var(--spacing-4);
  }
  .bottom-4-tablet {
    bottom: var(--spacing-4);
  }
  .left-4-tablet {
    left: var(--spacing-4);
  }
  .right-4-tablet {
    right: var(--spacing-4);
  }
}

@media (max-width: 1200px) {
  .margin-4-laptop {
    margin: var(--spacing-4);
  }
  .margin-top-4-laptop {
    margin-top: var(--spacing-4);
  }
  .margin-bottom-4-laptop {
    margin-bottom: var(--spacing-4);
  }
  .margin-left-4-laptop {
    margin-left: var(--spacing-4);
  }
  .margin-right-4-laptop {
    margin-right: var(--spacing-4);
  }
  .padding-4-laptop {
    padding: var(--spacing-4);
  }
  .padding-top-4-laptop {
    padding-top: var(--spacing-4);
  }
  .padding-bottom-4-laptop {
    padding-bottom: var(--spacing-4);
  }
  .padding-left-4-laptop {
    padding-left: var(--spacing-4);
  }
  .padding-right-4-laptop {
    padding-right: var(--spacing-4);
  }
  .top-4-laptop {
    top: var(--spacing-4);
  }
  .bottom-4-laptop {
    bottom: var(--spacing-4);
  }
  .left-4-laptop {
    left: var(--spacing-4);
  }
  .right-4-laptop {
    right: var(--spacing-4);
  }
}

@media (max-width: 1440px) {
  .margin-4-desktop {
    margin: var(--spacing-4);
  }
  .margin-top-4-desktop {
    margin-top: var(--spacing-4);
  }
  .margin-bottom-4-desktop {
    margin-bottom: var(--spacing-4);
  }
  .margin-left-4-desktop {
    margin-left: var(--spacing-4);
  }
  .margin-right-4-desktop {
    margin-right: var(--spacing-4);
  }
  .padding-4-desktop {
    padding: var(--spacing-4);
  }
  .padding-top-4-desktop {
    padding-top: var(--spacing-4);
  }
  .padding-bottom-4-desktop {
    padding-bottom: var(--spacing-4);
  }
  .padding-left-4-desktop {
    padding-left: var(--spacing-4);
  }
  .padding-right-4-desktop {
    padding-right: var(--spacing-4);
  }
  .top-4-desktop {
    top: var(--spacing-4);
  }
  .bottom-4-desktop {
    bottom: var(--spacing-4);
  }
  .left-4-desktop {
    left: var(--spacing-4);
  }
  .right-4-desktop {
    right: var(--spacing-4);
  }
}

/* RADIUS */
.radius-s {
  border-radius: var(--radius-s);
}

html,
body {
  width: 100%;
  min-height: 100%;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  background-color: var(--beige);
}

body {
  background-color: var(--white);
  color: var(--black);
  min-height: 100vh;

  /* text size adjust */
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  word-break: break-word;
  font: var(--font-p1-desktop-default);
}

*:-webkit-direct-focus {
  outline: none;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

table th {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

a {
  display: inline-block;
  text-decoration: none;
  color: var(--black);
}

button {
  display: block;
  background-color: transparent;
  color: var(--black);
  padding: 0;
  border: none;
}

hr {
  border: none;
  border-bottom: 1px solid var(--grey);
}

hr.thick {
  border-bottom-width: 2px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--black);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

@media (max-width: 1024px) {
  * {
    font: var(--font-p1-mobile-default);
  }
}

/*
****** MEWS RESET
*/
.mews-distributor {
  margin-top: var(--header-height) !important;
  height: calc(100% - var(--header-height)) !important;
}

@media (max-width: 768px) {
  .mews-distributor {
    margin-top: var(--mobile-header-height) !important;
    height: calc(100% - var(--mobile-header-height)) !important;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
.d-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.d-inline-flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.d-flex.f-wrap, .d-inline-flex.f-wrap {
  flex-wrap: wrap;
}

.d-flex.center, .d-inline-flex.center {
  align-items: center;
  justify-content: center;
}

.d-flex.a-start, .d-inline-flex.a-start {
  align-items: flex-start;
}

.d-flex.a-end, .d-inline-flex.a-end {
  align-items: flex-end;
}

.d-flex.a-center, .d-inline-flex.a-center {
  align-items: center;
}

.d-flex.a-stretch, .d-inline-flex.a-stretch {
  align-items: stretch;
}

.d-flex.j-start, .d-inline-flex.j-start {
  justify-content: flex-start;
}

.d-flex.j-end, .d-inline-flex.j-end {
  justify-content: flex-end;
}

.d-flex.j-center, .d-inline-flex.j-center {
  justify-content: center;
}

.d-flex.j-space-btw, .d-inline-flex.j-space-btw {
  justify-content: space-between;
}

.d-flex.j-space-around, .d-inline-flex.j-space-around {
  justify-content: space-around;
}

.d-flex.d-col, .d-inline-flex.d-col {
  flex-direction: column;
}

.d-flex.d-row, .d-inline-flex.d-row {
  flex-direction: row;
}

.d-flex.d-col-reverse, .d-inline-flex.d-col-reverse {
  flex-direction: column-reverse;
}

.d-flex.d-row-reverse, .d-inline-flex.d-row-reverse {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  .d-flex.mobile-a-start, .d-inline-flex.mobile-a-start {
    align-items: flex-start;
 }
  .d-flex.mobile-a-stretch, .d-inline-flex.mobile-a-stretch {
    align-items: stretch;
 }
  .d-flex.mobile-a-end, .d-inline-flex.mobile-a-end {
    align-items: flex-end;
 }
  .d-flex.mobile-a-center, .d-inline-flex.mobile-a-center {
    align-items: center;
 }
  .d-flex.mobile-j-end, .d-inline-flex.mobile-j-end {
    justify-content: flex-end;
 }
  .d-flex.mobile-j-start, .d-inline-flex.mobile-j-start {
    justify-content: flex-start;
 }
  .d-flex.mobile-j-center, .d-inline-flex.mobile-j-center {
    justify-content: center;
 }
  .d-flex.mobile-j-space-btw, .d-inline-flex.mobile-j-space-btw {
    justify-content: space-between;
 }
  .d-flex.mobile-d-col, .d-inline-flex.mobile-d-col {
    flex-direction: column;
 }
  .d-flex.mobile-d-col-reverse, .d-inline-flex.mobile-d-col-reverse {
    flex-direction: column-reverse;
 }
  .d-flex.mobile-d-reverse, .d-inline-flex.mobile-d-reverse {
    flex-direction: row-reverse;
 }
  .d-flex.mobile-f-wrap, .d-inline-flex.mobile-f-wrap {
    flex-wrap: wrap;
 }
}

@media (max-width: 1024px) {
  .d-flex.tablet-a-center, .d-inline-flex.tablet-a-center {
    align-items: center;
 }
  .d-flex.tablet-d-col, .d-inline-flex.tablet-d-col {
    flex-direction: column;
 }
  .d-flex.tablet-j-start, .d-inline-flex.tablet-j-start {
    justify-content: flex-start;
 }
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-full {
  flex: 1 1;
}

.flex-auto-shrink{
  flex: 1 0 auto;
}
.p-relative {
  position: relative;
  z-index: 1;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.flex-end {
  justify-content: flex-end;
}

:root {
  --header-height: 80px;
  --mobile-header-height: 64px;
  --header-height-residence: 82px;
  --header-mobile-height-residence: 56px;
}

/* Text alignments */
.text-center {
  text-align: center;
}

@media (max-width: 768px) {
  .mobile-text-left {
    text-align: left;
  }
}

/* Text decoration */
.underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

/* Text break and whitespace */

.text-nowrap {
  white-space: nowrap;
}

.word-break-all {
  word-break: break-all;
}


.react-calendar.calendar-input {
  border: none;
  width: 100%;
  font-family: Lato, Arial, Helvetica, sans-serif;
  color: var(--blue);
  background-color: transparent;
}

/* hide year navigation arrows */
.calendar-input .react-calendar__navigation__prev2-button,
.calendar-input .react-calendar__navigation__next2-button {
  display: none;
}

/* navigation header */
.calendar-input .react-calendar__navigation {
  height: auto;
  margin-bottom: var(--spacing-16);
}
.calendar-input .react-calendar__navigation__label__divider {
  display: none;
}
.calendar-input .react-calendar__navigation__label {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
}
.calendar-input .react-calendar__navigation__label:disabled,
.calendar-input .react-calendar__navigation__label:enabled:hover,
.calendar-input .react-calendar__navigation__label:enabled:focus {
  background-color: transparent;
}
.calendar-input .react-calendar__navigation__label__labelText {
  color: var(--blue);
  font-size: 18px;
  font-weight: 800;
  text-transform: capitalize;
}

/* month navigation arrows */
.calendar-input button.react-calendar__navigation__arrow,
.calendar-input button.react-calendar__navigation__arrow:hover,
.calendar-input button.react-calendar__navigation__arrow:focus,
.calendar-input button.react-calendar__navigation__arrow:enabled:hover,
.calendar-input button.react-calendar__navigation__arrow:enabled:focus,
.calendar-input button.react-calendar__navigation__arrow:disabled {
  background-color: transparent;
  min-width: initial;
}
.calendar-input button.react-calendar__navigation__arrow {
  color: var(--blue);
}
.calendar-input button.react-calendar__navigation__arrow:disabled {
  color: var(--grey);
}
.calendar-input .react-calendar__navigation__prev-button {
  padding-right: 20px;
}
.calendar-input .react-calendar__navigation__next-button {
  padding-left: 20px;
}

/* week days on top */
.calendar-input .react-calendar__month-view__weekdays__weekday {
  padding: 0 0 10px;
}
.calendar-input .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
  font-size: 12px;
  text-transform: lowercase;
  text-decoration: none;
  color: var(--blue);
  white-space: nowrap;
}
.calendar-input .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr::after {
  content: '.';
}

/* calendar tiles (days) */
.calendar-input .react-calendar__tile {
  padding: 10px 0;
  background-color: transparent;
  transition: background-color .2s ease-in-out;
}
.calendar-input .react-calendar__tile abbr {
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);
}
.calendar-input .react-calendar__tile:focus,
.calendar-input .react-calendar__tile:enabled:focus {
  background-color: transparent;
}
.calendar-input .react-calendar__tile:hover,
.calendar-input .react-calendar__tile:enabled:hover,
.calendar-input .react-calendar__tile--now:enabled:hover,
.calendar-input .react-calendar__tile--now:enabled:focus {
  background-color: var(--yellow-200);
}
.calendar-input .react-calendar__tile:disabled:not(.react-calendar__tile--active):not(.react-calendar__tile--hasActive) {
  opacity: 0.2;
}
.calendar-input .react-calendar__tile:disabled:hover {
  background-color: transparent;
}
.calendar-input .react-calendar__tile--now.react-calendar__tile--active:hover,
.calendar-input .react-calendar__tile--now.react-calendar__tile--active:focus,
.calendar-input .react-calendar__tile--now.react-calendar__tile--hasActive:hover,
.calendar-input .react-calendar__tile--now.react-calendar__tile--hasActive:focus,
.calendar-input .react-calendar__tile.react-calendar__tile--active,
.calendar-input .react-calendar__tile.react-calendar__tile--active:hover,
.calendar-input .react-calendar__tile.react-calendar__tile--active:focus,
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive,
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive:hover,
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive:focus {
  background-color: var(--yellow-500);
}
.calendar-input .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd),
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd) {
  background-color: var(--yellow-200);
}
.calendar-input .react-calendar__tile.react-calendar__tile--active abbr,
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive abbr,
.calendar-input .react-calendar__tile.react-calendar__tile--range abbr {
  font-weight: 800;
}
.calendar-input .react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
  height: 0;
  padding: 0;
  pointer-events: none;
}

/* month picker view */
.calendar-input .react-calendar__year-view .react-calendar__tile,
.calendar-input .react-calendar__decade-view .react-calendar__tile {
  padding: 16px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

/* Dropdown input field */

.select-field {
  position: relative;
  z-index: 1;
}

.select-field .select-field__control {
  min-height: 3.5rem;
  border: none;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
}

@media (max-width: 768px) {
  .select-field .select-field__control {
    min-height: 3rem;
  }
}

.select-field .select-field__control:hover,
.select-field .select-field__control:focus {
  border: none;
  box-shadow: none;
}

.select-field .select-field__value-container {
  padding: 0 var(--spacing-4) 0 var(--spacing-16);
}

.select-field .select-field__placeholder {
  font: var(--font-p1-desktop-default);
  color: var(--grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .select-field .select-field__placeholder {
    font: var(--font-p1-mobile-default);
  }
}

.select-field .select-field__input-container,
.select-field .select-field__single-value {
  font: var(--font-p1-desktop-default);
  color: var(--black);
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .select-field .select-field__input-container,
  .select-field .select-field__single-value {
    font: var(--font-p1-mobile-default);
  }
}

.select-field .select-field__indicators {
  padding-right: var(--spacing-8);
}

.select-field .select-field__indicators svg {
  color: var(--black);
  cursor: pointer;
}

.select-field .select-field__indicators .select-field__indicator-separator {
  display: none;
}

.select-field .select-field__indicators .select-field__dropdown-indicator {
  padding: 0 var(--spacing-8) 0 0;
}

/* Dropdown menu */

.select-field__menu-portal {
  opacity: 0;
  transition: opacity .2s ease-in-out;
  z-index: 2;
}

.select-field__menu-portal .select-field__menu {
  box-shadow: none;
  border: 1px solid var(--grey);
  border-radius: var(--radius-s);
  background-color: transparent;
  overflow: hidden;
}

.select-field__menu-portal .select-field__menu-list {
  padding: 0;
}

.select-field__menu-portal .select-field__menu-list .select-field__option,
.select-field__menu-portal .select-field__menu-list .select-field__option--is-focused,
.select-field__menu-portal .select-field__menu-list .select-field__option--is-selected,
.select-field__menu-portal .select-field__menu-list .select-field__option:hover,
.select-field__menu-portal .select-field__menu-list .select-field__option:focus {
  background-color: var(--white);
  color: var(--black);
}
.select-field__menu-portal .select-field__menu-list .select-field__option {
  padding: var(--spacing-12) var(--spacing-16);
  background-color: var(--white);
  font: var(--font-p2-desktop-default);
  cursor: pointer;
  transition: background-color .2s ease-in-out;
}

@media (max-width: 768px) {
  .select-field__menu-portal .select-field__menu-list .select-field__option {
    font: var(--font-p2-mobile-default);
  }
}

.select-field__menu-portal .select-field__menu-list .select-field__option.select-field__option--is-selected:hover,
.select-field__menu-portal .select-field__menu-list .select-field__option.select-field__option--is-selected {
  background-color: var(--yellow-500);
}

.select-field__menu-portal .select-field__menu-list .select-field__option:hover {
  background-color: var(--yellow-200);
}
.tippy-box[data-animation="fade"][data-state="hidden"] { opacity: 0; }

.tippy-box[data-theme~='customtheme'] {
  padding: var(--spacing-12);
  background-color: var(--yellow-200);
  box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius-s);
}

.tippy-box .tippy-content,
.tippy-box .tippy-content > div {
  padding: 0;
  font: var(--font-p2-desktop-default);
  color: var(--black);
}

@media (max-width: 768px) {
  .tippy-box .tippy-content,
  .tippy-box .tippy-content > div {
    font: var(--font-p2-mobile-default);
  }
}

.tippy-box[data-theme~='customtheme'] .tippy-arrow {
  color: var(--yellow-200);
}


.pac-container {
  box-shadow: none;
  padding: var(--spacing-12);
  border: 1px solid var(--grey);
  border-radius: var(--radius-s);
  margin-top: var(--spacing-8);
}

.pac-container .pac-item {
  border: none;
  padding: 0;
  cursor: pointer;
}

.pac-container .pac-item span {
  font-size: 0.875rem;
}

.hdpi.pac-logo:after {
  display: none;
}

.modal {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  /* closed */
  opacity: 0;
  visibility: hidden;

  transition: .2s ease-in-out;
  transition-property: opacity, visibility;
}

.modal.is-open {
  opacity: 1;
  visibility: visible;
}

.modal.is-open .modal_window {
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .modal.is-open .modal_window {
    transform: translate(0, 0);
  }
}

.modal .modal_layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.6;
}

.modal_window {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: calc(100% - 20px);
  max-width: 1200px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  background-color: var(--beige);
  border-radius: var(--radius-s);

  /* closed */
  transform: translate(-50%, calc(-50% + 30px));

  transition: .2s ease-in-out;
  transition-property: transform;
}

@media (max-width: 768px) {
  .modal_window {
    top: initial;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;

    /* closed */
    transform: translate(0, 30px);
  }
}

.modal_window .modal_body {
  padding: var(--spacing-24);
}

@media (max-width: 768px) {
  .modal_window .modal_body {
    padding: var(--spacing-24) var(--spacing-16);
  }
}


/* LIBS */

/* cyrillic-ext */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c2958e922dd5a3df-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/08ff561b69b8d3e5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/75f831700aaea22d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7f78a3adbb2adbe3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/f8a669a999c483ca-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c2958e922dd5a3df-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/08ff561b69b8d3e5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/75f831700aaea22d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7f78a3adbb2adbe3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f8a669a999c483ca-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c2958e922dd5a3df-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/08ff561b69b8d3e5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/75f831700aaea22d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7f78a3adbb2adbe3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oswald_8d88ce';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f8a669a999c483ca-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Oswald_Fallback_8d88ce';src: local("Arial");ascent-override: 145.07%;descent-override: 35.14%;line-gap-override: 0.00%;size-adjust: 82.24%
}.__className_8d88ce {font-family: '__Oswald_8d88ce', '__Oswald_Fallback_8d88ce';font-style: normal
}.__variable_8d88ce {--font-oswald: '__Oswald_8d88ce', '__Oswald_Fallback_8d88ce'
}

/* latin-ext */
@font-face {
  font-family: '__Lato_ba3ec8';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6ec89c3f4265bc9b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_ba3ec8';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/155cae559bbd1a77-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Lato_ba3ec8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df658da56627d0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_ba3ec8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4de1fea1a954a5b6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Lato_ba3ec8';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/af4d27004aa34222-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_ba3ec8';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/7ff6869a1704182a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lato_Fallback_ba3ec8';src: local("Arial");ascent-override: 101.32%;descent-override: 21.87%;line-gap-override: 0.00%;size-adjust: 97.42%
}.__className_ba3ec8 {font-family: '__Lato_ba3ec8', '__Lato_Fallback_ba3ec8';font-style: normal
}.__variable_ba3ec8 {--font-lato: '__Lato_ba3ec8', '__Lato_Fallback_ba3ec8'
}

